<template>
  <div class="">
    <b-form enctype="multipart/form-data" @submit.prevent="submitUpdate">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">
                      Título
                      <i class="text-danger bi bi-record-circle"></i>
                    </label>
                    <input
                      require
                      type="text"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                      v-model="item.title"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">
                      Professor(a)
                      <i class="text-danger bi bi-record-circle"></i>
                    </label>
                    <v-select
                      ref="selectTeacher"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.teacher"
                      placeholder="Digite o título do material"
                      :options="optionsTeachers"
                      @search="fetchTeachersOptions"
                      :class="{ 'is-invalid': $v.item.teacher.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectTeacher.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card header="Associação">
            <div class="col-12">
              <div class="form-group">
                <i class="text-danger bi bi-record-circle"></i>

                <label for=""> Grade </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.grid"
                  placeholder="Digite a grade para o material"
                  :options="optionsGrids"
                  @search="fetchGridsOptions"
                  :class="{ 'is-invalid': $v.item.grid.$error }"
                >
                </v-select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <i class="text-danger bi bi-record-circle"></i>

                <label for=""> Disciplinas </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.discipline"
                  placeholder="Selecione uma disciplina"
                  :options="optionsDisciplines"
                  :class="{ 'is-invalid': $v.item.discipline.$error }"
                >
                </v-select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <i class="text-danger bi bi-record-circle"></i>
                <label for=""> Aula </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="id"
                  v-model="item.lesson"
                  placeholder="Selecione uma lição"
                  :options="optionsLessons"
                  :class="{ 'is-invalid': $v.item.lesson.$error }"
                >
                  <template
                    v-slot:option="{
                      teacher_name,
                      title,
                      number,
                      date,
                      start_time,
                      end_time,
                    }"
                  >
                    <b-row align-v="center">
                      <b-col cols="12">
                        <b-badge variant="primary">Aula {{ number }}</b-badge>
                        <b-badge variant="info" class="ml-1">{{
                          new Date(date).toLocaleDateString("pt-BR")
                        }}</b-badge>
                        <span class="ml-1 text-muted">
                          {{ start_time }} - {{ end_time }}
                        </span>
                        <br />
                        <b-icon-person class="text-primary"></b-icon-person>
                        <span class="font-weight-bold ml-1">{{
                          teacher_name || "Sem Professor"
                        }}</span>
                        -
                        <span>{{ title }}</span>
                      </b-col>
                    </b-row>
                  </template>
                </v-select>
              </div>
            </div>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card header="Arquivo">
            <p>
              <a :href="item.url" target="_blank" class="text-primary"
                >Clique aqui</a
              >
              para visualizar o arquivo.
            </p>
            <b-card-text>
              <input
                style="padding: 0.4rem 0.5rem"
                type="file"
                id="file"
                ref="file"
                class="form-control"
                @change="handleFileUpload()"
              />
            </b-card-text>
            <div class="alert alert-primary p-1 mn-0">
              Para substituir o arquivo atual, selecione um novo arquivo.
            </div>
          </b-card>
          <ButtonsActionsFooter
            routerBack="materials-v2-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BEmbed,
  BLink,
  BAlert,
  BCardFooter,
  BImg,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

export default {
  components: {
    BCard,
    BEmbed,
    BCardText,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BLink,
    BAlert,
    ButtonsActionsFooter,
    BCardFooter,
    BImg,
    BBadge,
  },
  data() {
    return {
      preview: "",
      file: "",
      uuid: null,
      submited: false,
      filteredOptions: [],
      wasUploads: 0,
      snowOption: {
        theme: "snow",
        placeholder: "Descrição da aula",
        height: "300",
      },
      item: {
        title: "",
        file: null,
        media_type_id: 1,
        teacher: [],
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
        grid: null,
        discipline: null,
        lesson: null,
      },
      optionsGrids: [],
      optionsDisciplines: [],
      optionsLessons: [],
      optionsTeachers: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
    };
  },
  watch: {
    "item.grid"(newGrid) {
      if (newGrid) {
        this.fetchGridDisciplines(newGrid.code);
      } else {
        this.item.discipline = null;
        this.item.lesson = null;
        this.optionsDisciplines = [];
        this.optionsLessons = [];
      }
    },

    "item.discipline"(newDiscipline) {
      if (newDiscipline) {
        this.fetchLessons(newDiscipline.code, this.item.grid.code);
      } else {
        this.item.lesson = null;
        this.optionsLessons = [];
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      teacher: {
        required,
      },
      grid: {
        required,
      },
      discipline: {
        required,
      },
      lesson: {
        required,
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.getData();
  },
  computed: {
    ...mapState("Material", ["materials", "material", "loading"]),
    ...mapState("Teacher", ["teachers", "searchTeachers"]),
  },
  methods: {
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    async fetchTeachersOptions(term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    async fetchGridsOptions(term) {
      if (term.length > 2) {
        this.optionsGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    async fetchGridDisciplines(grid_id) {
      try {
        const disciplines = await this.$store.dispatch("Grid/disciplines", {
          grid_id,
        });
        this.optionsDisciplines = disciplines;
      } catch (error) {
        console.error("Erro ao buscar disciplinas:", error);
      }
    },
    async fetchLessons(discipline_id, grid_id) {
      try {
        const lessons = await this.$store.dispatch("Grid/disciplineLessons", {
          discipline_id,
          grid_id,
        });
        this.optionsLessons = lessons;
      } catch (error) {
        console.error("Erro ao buscar lições:", error);
      }
    },

    handleFileUpload() {
      this.item.file = this.$refs.file.files[0];
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name.replaceAll(" ", "-").toLowerCase();
      }
      return `${files.length} arquivos selecionados`;
    },
    submitUpdate() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);
      formData.append("title", this.item.title);
      formData.append("teacher", this.item.teacher.code);
      formData.append("media_type_id", this.item.media_type_id);
      formData.append("lesson_id", this.item.lesson ? this.item.lesson.id : "");
      formData.append(
        "discipline_id",
        this.item.discipline ? this.item.discipline.id : ""
      );

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Material/update", { uuid: this.item.uuid, data: formData })
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            this.isAddNewMaterialSidebarActive = false;
            this.$router.push({ name: "materials-v2-list" });
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      await this.$store.dispatch("Material/show", this.$route.params.uuid);

      if (this.material) {
        this.item = {
          uuid: this.material.data.uuid,
          title: this.material.data.title,
          url: this.material.data.url,
          media_type_id: 1,
          teacher: this.material.data.teacher,
          tags: {
            subject: this.material.data.tags.subject,
            contest: this.material.data.tags.contest,
            timeline: this.material.data.tags.timeline,
          },
          lesson: this.material.data.lesson,
          discipline: this.material.data.discipline,
          grid: this.material.data.grid,
        };

        if (this.item.grid?.code) {
          await this.fetchGridDisciplines(this.item.grid.code);
        }

        if (this.item.discipline?.code && this.item.grid?.code) {
          await this.fetchLessons(
            this.item.discipline.code,
            this.item.grid.code
          );
        }
      }
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Media/deleteFile", { uuid: this.item.uuid })
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Deletado!",
                text: "Arquivo deletado com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    uploadFile() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Media/create", formData)
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            // this.$router.go(0);
            this.isAddNewMaterialSidebarActive = false;
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
};
</script>

<style>
.beforeUpload .icon {
  width: 50px !important;
}
</style>
