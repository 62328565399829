var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitUpdate.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Informações" } },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Título "),
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.title,
                                  expression: "item.title",
                                },
                              ],
                              staticClass: "form-control",
                              class: { "is-invalid": _vm.$v.item.title.$error },
                              attrs: { require: "", type: "text" },
                              domProps: { value: _vm.item.title },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Professor(a) "),
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectTeacher",
                                  class: {
                                    "is-invalid": _vm.$v.item.teacher.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título do material",
                                    options: _vm.optionsTeachers,
                                  },
                                  on: { search: _vm.fetchTeachersOptions },
                                  model: {
                                    value: _vm.item.teacher,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "teacher", $$v)
                                    },
                                    expression: "item.teacher",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectTeacher.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("b-card", { attrs: { header: "Associação" } }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Grade "),
                        ]),
                        _c("v-select", {
                          class: { "is-invalid": _vm.$v.item.grid.$error },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Digite a grade para o material",
                            options: _vm.optionsGrids,
                          },
                          on: { search: _vm.fetchGridsOptions },
                          model: {
                            value: _vm.item.grid,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "grid", $$v)
                            },
                            expression: "item.grid",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Disciplinas "),
                        ]),
                        _c("v-select", {
                          class: {
                            "is-invalid": _vm.$v.item.discipline.$error,
                          },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Selecione uma disciplina",
                            options: _vm.optionsDisciplines,
                          },
                          model: {
                            value: _vm.item.discipline,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "discipline", $$v)
                            },
                            expression: "item.discipline",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _c("label", { attrs: { for: "" } }, [_vm._v(" Aula ")]),
                        _c("v-select", {
                          class: { "is-invalid": _vm.$v.item.lesson.$error },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "id",
                            placeholder: "Selecione uma lição",
                            options: _vm.optionsLessons,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (ref) {
                                var teacher_name = ref.teacher_name
                                var title = ref.title
                                var number = ref.number
                                var date = ref.date
                                var start_time = ref.start_time
                                var end_time = ref.end_time
                                return [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-badge",
                                            { attrs: { variant: "primary" } },
                                            [_vm._v("Aula " + _vm._s(number))]
                                          ),
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { variant: "info" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  new Date(
                                                    date
                                                  ).toLocaleDateString("pt-BR")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "ml-1 text-muted" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(start_time) +
                                                  " - " +
                                                  _vm._s(end_time) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c("b-icon-person", {
                                            staticClass: "text-primary",
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold ml-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  teacher_name ||
                                                    "Sem Professor"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" - "),
                                          _c("span", [_vm._v(_vm._s(title))]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.item.lesson,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "lesson", $$v)
                            },
                            expression: "item.lesson",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  { attrs: { header: "Arquivo" } },
                  [
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "text-primary",
                          attrs: { href: _vm.item.url, target: "_blank" },
                        },
                        [_vm._v("Clique aqui")]
                      ),
                      _vm._v(" para visualizar o arquivo. "),
                    ]),
                    _c("b-card-text", [
                      _c("input", {
                        ref: "file",
                        staticClass: "form-control",
                        staticStyle: { padding: "0.4rem 0.5rem" },
                        attrs: { type: "file", id: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.handleFileUpload()
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "alert alert-primary p-1 mn-0" }, [
                      _vm._v(
                        " Para substituir o arquivo atual, selecione um novo arquivo. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "materials-v2-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }